//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PrescriptionService } from '@/services/prescription.service';
import { format, fromUnixTime } from 'date-fns';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      buttonDisabled: false,
      has90DayError: false,
      progesteronePrescription: null,
      processing: false
    };
  },

  watch: {
    showDialog(show) {
      if (!show) {
        this.onModalExit();
      }
    }

  },
  methods: {
    async restartPrescription() {
      this.alert.show = false;

      try {
        this.buttonDisabled = true;
        this.processing = true;

        if (this.has90DayError) {
          await PrescriptionService.restartTreatment(this.progesteronePrescription.patientid, this.progesteronePrescription.id, this.has90DayError ? {
            force: true
          } : null);
        }

        const {
          data
        } = await PrescriptionService.restartTreatment(this.rowData.patientid, this.rowData.id, this.has90DayError ? {
          force: true
        } : null);

        if (data) {
          this.buttonDisabled = false;
          await sleep(3000);
          await this.$emit('refresh-prescriptions', 'restart', this.rowData.id);
          this.has90DayError = false;
          this.progesteronePrescription = null;
          this.showDialog = false;
        }
      } catch (err) {
        var _err$response, _err$response$data, _err$response$data$me, _err$response3, _err$response3$data;

        if (err !== null && err !== void 0 && (_err$response = err.response) !== null && _err$response !== void 0 && (_err$response$data = _err$response.data) !== null && _err$response$data !== void 0 && (_err$response$data$me = _err$response$data.message) !== null && _err$response$data$me !== void 0 && _err$response$data$me.includes('90 days')) {
          var _err$response2, _err$response2$data;

          this.has90DayError = true;
          this.progesteronePrescription = err === null || err === void 0 ? void 0 : (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : (_err$response2$data = _err$response2.data) === null || _err$response2$data === void 0 ? void 0 : _err$response2$data.body;
          this.buttonDisabled = false;
        } else if (err !== null && err !== void 0 && (_err$response3 = err.response) !== null && _err$response3 !== void 0 && (_err$response3$data = _err$response3.data) !== null && _err$response3$data !== void 0 && _err$response3$data.message) {
          var _err$response4, _err$response4$data;

          this.buttonDisabled = false;
          this.alert.message = err === null || err === void 0 ? void 0 : (_err$response4 = err.response) === null || _err$response4 === void 0 ? void 0 : (_err$response4$data = _err$response4.data) === null || _err$response4$data === void 0 ? void 0 : _err$response4$data.message;
          this.alert.show = true;
        } else {
          this.buttonDisabled = false;
          this.alert.message = err;
          this.alert.show = true;
        }
      } finally {
        await sleep(3000);
        this.processing = false;
      }
    },

    getRefillString(med) {
      if (med.refills) {
        if (['SCHEDULED', 'WAITING', 'PROCESSING', 'MIGRATED', 'DRAFTED'].includes(med.prescriptionstatus)) {
          return `Refills: ${med.refillinput}`;
        }

        const refills = med.refills - med.paidrefills;

        if (med.paidrefills === 0 && (med.paymentstatus === 'FAILED' || med.prescriptionstatus === 'PAUSED' || med.prescriptionstatus === 'CANCELLED')) {
          return `${med.refills - med.paidrefills - 1} out of ${med.refills - 1} refills`;
        }

        if (med.refills && med.paidrefills) {
          return `${refills} out of ${med.refills - 1} refills`;
        } else {
          return 'One time purchase';
        }
      } else {
        return 'No refills';
      }
    },

    getCSSClasses(prescription) {
      const status = prescription.prescriptionstatus.replace(/\s+/g, '-').toLowerCase();
      return [status];
    },

    parseUnixDate(date, formatString) {
      return format(fromUnixTime(date), formatString);
    },

    onModalExit() {
      this.alert.show = false;
      this.has90DayError = false;
      this.progesteronePrescription = null;
    }

  }
};